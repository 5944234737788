<template>
  <v-container>
    <v-row justify="center">
      <!-- <v-col cols="12" md="6" lg="4">
        <room-auto-complete v-model="room"></room-auto-complete>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <DataTableSSR
            apiEndPoint="users/"
            :headers="headers"
            :instantLoad="true"
          ></DataTableSSR>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataTableSSR from "@/components/global/DataTableSSR.vue";
import RoomAutoComplete from "@/components/RoomAutoComplete.vue";
export default {
  components: { DataTableSSR, RoomAutoComplete },
  data: () => ({
    room: "",
    headers: [
      {
        text: "Name",
        value: "full_name",
        sortable: false,
      },
      {
        text: "Email",
        value: "email",
        sortable: false,
      },
      {
        text: "User Type",
        value: "user_type",
      },
    ],
  }),
};
</script>

<style></style>
